/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Image, Menu, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Místo svatby"} description={"Informace o ubytování a místu svatby."}>
        <ColumnCover name={"bm2o91xwgq"}>
        </ColumnCover>


        <Column className="mt--10 pb--0 pt--10" name={"misto_svatby"}>
          
          <ColumnWrap className="column__flex --center el--1 mb--10 mt--20" style={{"maxWidth":1348}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--43" content={"Místo svatby a okolí chaty Spindler"}>
              </Title>

              <Text className="text-box ff--3 fs--14 w--400" style={{"maxWidth":1054}} content={"Místo svatby se nachází na hřebenových loukách Krkonoš, v těsné blízkosti hranic s Polskem, v nadmořské výšce 1 235 m.n.m. Tato lokalita Krkonoš se nazývá \"Sedmidolí\". Vzdálenost od centra známého horského městečka Špindlerův Mlýn je cca 8 km. Nemusíte se však bát. Sotva sníh pokryje louky, není možné se sem dopravit autem. Dopravu do zdejších bud zajišťují rolby a sněžné skůtry. Výhodou je, že místní horské chatičky jsou díky tomu stranou od přelidněných turistických tras. Navíc jsou velmi útulné a rodinné. \"Naši louku\" tvoří chaty Spindler, Moravská Bouda, Novopacká Bouda a Dvořákova Bouda a protéká ji Červený potok.\n\n<br><br>Přáli bychom si tu nejen strávit naši svatbu daleko od civilizace a zapomenout na bláznivý předešlý rok, ale chtěli bychom se s Vámi o tento krásný a ukrytý kousek naší země podělit. Vládne tu pohoda, klid, sníh, mráz a boží výhled... ;-)\n\n<br><br>Na louce se nachází dvě sjezdovky, osvětlené sjezdovce vlevo se říká \"u Dvořákovy boudy\", její horní část je spíše červená a zbytek modrá pro začátečníky a hlavně rodiny. Po dohodě lze domluvit i večerní lyžování, pokud nás bude dost. Vpravo (zde na fotce není vidět) se rozprostírá na louce ještě kratší, ale za to dost široká sjezdovka s lanovým vlekem pro menší děti a úplné začátečníky.\nKdo bude chtít zkusit lyže, i třeba poprvé v životě, naučíme ho sjet malý kopec a zabrzdit ;-)"}>
              </Text>

              <Button className="btn-box" href={"/kontakt"} target={"_blank"} content={"Jak se dostat k chatě..."}>
              </Button>

              <Image className="mb--20 pt--20" alt={""} src={"https://cdn.swbpg.com/t/7361/a5983816a6fe4bc2bce6d98eea8bcdd2_s=860x_.jpg"} svg={false} sizes={"100vw"} style={{"maxWidth":1162}} srcSet={"https://cdn.swbpg.com/t/7361/a5983816a6fe4bc2bce6d98eea8bcdd2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/a5983816a6fe4bc2bce6d98eea8bcdd2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/a5983816a6fe4bc2bce6d98eea8bcdd2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/a5983816a6fe4bc2bce6d98eea8bcdd2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7361/a5983816a6fe4bc2bce6d98eea8bcdd2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <ColumnCover name={"benezbs0zi"}>
        </ColumnCover>


        <Column className="pb--10 pt--0" name={"misto_svatby_2"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1320}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box ff--3 fs--14 w--400" style={{"maxWidth":962}} content={"Okolo chaty vede běžkařská trasa a spousta turistických cest. Po hřebeni například známá cesta \"česko-polského přátelství\". V chatě se půjčují sněžnice, boby a píší, že i snowboardy a běžky. Je však lepší mít vlastní. Pro velké i malé děti budou k dispozici sáně a ježdíky\na celá louka na stavění sněhuláků..."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"misto_svatby_3"} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/ec682c824114410c9c56d99b9f61fd99_s=860x_.JPG"} svg={false} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/ec682c824114410c9c56d99b9f61fd99_s=350x_.JPG 350w, https://cdn.swbpg.com/t/7361/ec682c824114410c9c56d99b9f61fd99_s=660x_.JPG 660w, https://cdn.swbpg.com/t/7361/ec682c824114410c9c56d99b9f61fd99_s=860x_.JPG 860w, https://cdn.swbpg.com/t/7361/ec682c824114410c9c56d99b9f61fd99_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/7361/ec682c824114410c9c56d99b9f61fd99_s=2000x_.JPG 2000w"} content={null} position={{"x":"-0.1364256480218281%","y":"-22.559322033898304%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/49cc1bcfbdba42118b28076be677c024_s=860x_.jpg"} svg={false} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/49cc1bcfbdba42118b28076be677c024_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/49cc1bcfbdba42118b28076be677c024_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/49cc1bcfbdba42118b28076be677c024_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/49cc1bcfbdba42118b28076be677c024_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7361/49cc1bcfbdba42118b28076be677c024_s=2000x_.jpg 2000w"} content={null} position={{"x":"0%","y":"-24.2320819112628%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/d9794b1272d4499ea45a486375fe8d4f_s=860x_.jpg"} svg={false} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/d9794b1272d4499ea45a486375fe8d4f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/d9794b1272d4499ea45a486375fe8d4f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/d9794b1272d4499ea45a486375fe8d4f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/d9794b1272d4499ea45a486375fe8d4f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7361/d9794b1272d4499ea45a486375fe8d4f_s=2000x_.jpg 2000w"} content={null} position={{"x":"0%","y":"-11.730375426621162%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/82fc7cb3e66d4af3bca50fcdd0601749_s=860x_.jpg"} svg={false} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/82fc7cb3e66d4af3bca50fcdd0601749_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/82fc7cb3e66d4af3bca50fcdd0601749_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/82fc7cb3e66d4af3bca50fcdd0601749_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/82fc7cb3e66d4af3bca50fcdd0601749_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7361/82fc7cb3e66d4af3bca50fcdd0601749_s=2000x_.jpg 2000w"} content={null} position={{"x":"0%","y":"-20.6779661016949%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" name={"misto_svatby_4"} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/aa1d68ef4d7948598c568f7dfed3a452_s=660x_.jpg"} svg={false} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/aa1d68ef4d7948598c568f7dfed3a452_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/aa1d68ef4d7948598c568f7dfed3a452_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/aa1d68ef4d7948598c568f7dfed3a452_s=860x_.jpg 860w"} content={null} position={{"x":"0%","y":"-19.322033898305087%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/e958194534104244843437a30c546686_s=660x_.jpg"} svg={false} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/e958194534104244843437a30c546686_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/e958194534104244843437a30c546686_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/e958194534104244843437a30c546686_s=860x_.jpg 860w"} content={null} position={{"x":"0%","y":"0%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/7c1421d8f0244a238c9ed169360fe4ff_s=660x_.jpg"} svg={false} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/7c1421d8f0244a238c9ed169360fe4ff_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/7c1421d8f0244a238c9ed169360fe4ff_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/7c1421d8f0244a238c9ed169360fe4ff_s=860x_.jpg 860w"} content={null} position={{"x":"-37.124105011933175%","y":"0%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/0da45d8bc1304acbbc22324540356c3f_s=860x_.jpg"} svg={false} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/0da45d8bc1304acbbc22324540356c3f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/0da45d8bc1304acbbc22324540356c3f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/0da45d8bc1304acbbc22324540356c3f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/0da45d8bc1304acbbc22324540356c3f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7361/0da45d8bc1304acbbc22324540356c3f_s=2000x_.jpg 2000w"} content={null} position={{"x":"0%","y":"-17.966101694915245%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--10" name={"chata_spindler"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1352}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--43" content={"Chata Spindler<br>"}>
              </Title>

              <Text className="text-box ff--3 fs--14 w--400" style={{"maxWidth":1018}} content={"Jedná se o jednu z nejvýše položených horských chat lokality Sedmidolí.\nNachází se na úplných vrcholcích Krkonoš v nadmořské výšce 1 235 m.n.m. a proslýchá se o ní, že je rodným domem pana Spindlera,\njehož předkové jsou zakladateli města Špindlerův Mlýn.\n"}>
              </Text>

              <Image className="mt--20" alt={""} src={"https://cdn.swbpg.com/t/7361/4e0a952b12b74ed0881c850b76b7b760_s=860x_.JPG"} svg={false} sizes={"100vw"} style={{"maxWidth":1166}} srcSet={"https://cdn.swbpg.com/t/7361/4e0a952b12b74ed0881c850b76b7b760_s=350x_.JPG 350w, https://cdn.swbpg.com/t/7361/4e0a952b12b74ed0881c850b76b7b760_s=660x_.JPG 660w, https://cdn.swbpg.com/t/7361/4e0a952b12b74ed0881c850b76b7b760_s=860x_.JPG 860w, https://cdn.swbpg.com/t/7361/4e0a952b12b74ed0881c850b76b7b760_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/7361/4e0a952b12b74ed0881c850b76b7b760_s=2000x_.JPG 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--10 pt--10" name={"chata_spindler_vybaveni"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box ff--3 fs--14 w--400" style={{"maxWidth":1022}} content={"Chata Spindler nabízí svým hostům ubytování v útulných, dřevem vonících dvoulůžkových, trojlůžkových, jednom čtyřlůžkovém a dalším osmilůžkovém pokoji. Maximální kapacita chaty je 24 lůžek.\nSpolečné, v roce 2009 nově zrekonstruované, koupelny a sociální zařízení jsou umístěny na patře a jsou oddělené zvlášť pro ženy a muže.\nV přízemí se nacházejí druhé toalety. Naproti vchodu byla zřízena malá jídelna, kde se podává bohatá snídaně formou bufetu. K dispozici je mikrovlnná trouba a elektrická konvice na čaj. Ve společenské místnosti je dispozici krb, TV, DVD a spousta společenských her.\nV celé chatě funguje připojení na internet. Pro děti je zde dětský koutek s hračkami, v dámské koupelně je k dispozici dětská vanička, přebalovací pult, nočník a dětské prkénko.\n<br>V zimních měsících si můžete zapůjčit sportovní\nvybavení – sněžnice, běžky, snowboardy, boby, snowtubing...\n"}>
              </Text>

              <Button className="btn-box" href={"/kontakt"} content={"Jak se dostat k chatě..."}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" name={"chata_spindler_fotogalerie"} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/426dc8408c9c4b6b930c947fa821e3b3_s=660x_.JPG"} svg={false} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/426dc8408c9c4b6b930c947fa821e3b3_s=350x_.JPG 350w, https://cdn.swbpg.com/t/7361/426dc8408c9c4b6b930c947fa821e3b3_s=660x_.JPG 660w"} content={null} position={{"x":"-2.1828103683492497%","y":"-31.54237288135592%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/e443040a16dc49ecb3d51b368f157ab7_s=660x_.jpg"} svg={false} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/e443040a16dc49ecb3d51b368f157ab7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/e443040a16dc49ecb3d51b368f157ab7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/e443040a16dc49ecb3d51b368f157ab7_s=860x_.jpg 860w"} content={null} position={{"x":"0%","y":"0%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/53ed23f1a42048768b8b255b6cc02159_s=660x_.jpg"} svg={false} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/53ed23f1a42048768b8b255b6cc02159_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/53ed23f1a42048768b8b255b6cc02159_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/53ed23f1a42048768b8b255b6cc02159_s=860x_.jpg 860w"} content={null} position={{"x":"0%","y":"0%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/245351ef9924495fbb19a08fcd8369eb_s=660x_.jpg"} svg={false} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/245351ef9924495fbb19a08fcd8369eb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/245351ef9924495fbb19a08fcd8369eb_s=660x_.jpg 660w"} content={null} position={{"x":"0%","y":"-49.15254237288136%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--10" name={"finska_sauna"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--43" content={"Finská sauna<br>"}>
              </Title>

              <Text className="text-box ff--3 fs--14 w--400 mb--20" style={{"maxWidth":976}} content={"Až se začne stmívat, zatopí nám pod kádí, nalijeme si něco lahodného do sklenky a zalezeme do horké lázně pod širým nebem...<br>Vemte si s sebou plavky a župan nebo velkou osušku na přebíhání mezi chatou a horkou lázní ;-)<br><br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/t/7361/4fe52a014b6841fbbdd16ca3d6038f74_s=660x_.jpg"} svg={false} sizes={"100vw"} style={{"maxWidth":1180}} srcSet={"https://cdn.swbpg.com/t/7361/4fe52a014b6841fbbdd16ca3d6038f74_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/4fe52a014b6841fbbdd16ca3d6038f74_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/4fe52a014b6841fbbdd16ca3d6038f74_s=860x_.jpg 860w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu pb--30 pt--20" menu={true} name={"misto_svatby_paticka"}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box ff--3 fs--26" content={"Svatba Plašanů<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/"} target={""} content={"Svatba Plašanů"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/pro-svatebcany"} target={""} content={"Pro svatebčany"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"#bm2o91xwgq"} target={""} content={"Zpět nahoru"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kontakt"} target={""} content={"Kontakty a mapy"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}